import "amazon-connect-streams";

const ccpUrl = "https://vf-gsuite-test.awsapps.com/connect/ccp-v2/";
let agentMediaLegId, agentResourceId, loginToken = '';
let curAgent = {};
// initialise the streams api
init();


function init() {
  // initialize the ccp
  connect.core.initCCP(containerDiv, {
    ccpUrl: ccpUrl /*REQUIRED*/,
    loginPopup: true /*optional, default TRUE*/,
    region: "us-east-1" /*REQUIRED for chat, optional otherwise*/,
    softphone: {
      /*optional*/ allowFramedSoftphone: true /*optional*/,
    },
  });
}

connect.agent((agent) => {
  curAgent = agent;
  let agentQueueArn = curAgent.getConfiguration().routingProfile.queues.find(element => element.name === null).queueId;
  console.debug(`Agent Queue ARN = ${agentQueueArn}`);
  agentResourceId =  agentQueueArn.substring(agentQueueArn.lastIndexOf('/')+1);
  let agentResourceDiv = document.getElementById("agentResource");
  agentResourceDiv.innerHTML = agentResourceId;
  console.debug(`Agent Resource ID = ${agentResourceId}`);
});

let curConnections = {};
connect.contact((contact) => {
  contact.onConnected(() => {
    curConnections = curAgent.getContacts()[0].getConnections();
    agentMediaLegId = curConnections[0].connectionId;
    let mediaLegDiv = document.getElementById("mediaLeg");
    mediaLegDiv.innerHTML = agentMediaLegId;
    console.info(`agentMediaLegId = ${agentMediaLegId}`);  
  });
});


